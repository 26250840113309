import { ethers } from "ethers";
import { addresses } from "../constants";
import { abi as OlympusStakingv2 } from "../abi/OlympusStakingv2.json";
import { abi as sOHMv2 } from "../abi/sOhmv2.json";
import { setAll, getTokenPrice, getMarketPrice } from "../helpers";
import { createSlice, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAsyncThunk } from "./interfaces";
import { abi as ierc20Abi } from "../abi/IERC20.json";
import { Decimal } from "decimal.js"
import { abi as wsOHM } from "../abi/wsOHM.json";
import { abi as vyABI } from "../abi/governance/vy.json";
import { abi as stake } from "../abi/governance/stake.json";
const initialState = {
  loading: false,
  loadingMarketPrice: false,
};

export const loadAppDetails = createAsyncThunk(
  "app/loadAppDetails",
  async ({ networkID, provider }: IBaseAsyncThunk, { dispatch }) => {
    const ohmContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20Abi, provider);
    const StakeBalance = Number(await ohmContract.balanceOf(addresses[networkID].STAKING_ADDRESS));
    const Price = await getMarketPrice({ networkID, provider });
    const stakingTVL = StakeBalance * Price / (Math.pow(10, 6));
    let marketPrice;
    try {
      const originalPromiseResult = await dispatch(
        loadMarketPrice({ networkID: networkID, provider: provider }),
      ).unwrap();
      marketPrice = originalPromiseResult?.marketPrice;
    } catch (rejectedValueOrSerializedError) {
      // handle error here
      console.error("Returned a null response from dispatch(loadMarketPrice)");
      return;
    }
    if (!provider) {
      console.error("failed to connect to provider, please connect your wallet");
      return {
        stakingTVL,
        marketPrice,
      };
    }
    const currentBlock = await provider.getBlockNumber();
    const stakingContract = new ethers.Contract(
      addresses[networkID].STAKING_ADDRESS as string,
      OlympusStakingv2,
      provider,
    );
    const warmupPeriod = await stakingContract.warmupPeriod()

    const sohmMainContract = new ethers.Contract(addresses[networkID].SOHM_ADDRESS as string, sOHMv2, provider);
    // Calculating staking
    const epoch = await stakingContract.epoch();
    const stakingReward = epoch.distribute;
    const endBlock = epoch.endBlock;
    const circ = await sohmMainContract.circulatingSupply();
    const stakingRebase = stakingReward / circ;
    const oneDayRate = Math.pow(1 + stakingRebase, 3) - 1;
    const fiveDayRate = Math.pow(1 + stakingRebase, 5 * 3) - 1;
    // const stakingAPY1 = Math.pow(1 + stakingRebase, 365 * 3) - 1;
    const stakingAPY = (new Decimal("1").add(new Decimal(stakingRebase))).pow(365 * 3).sub(new Decimal("1"))
    // Current index
    const currentIndex = await stakingContract.index();

    const wsohmContract = new ethers.Contract(addresses[networkID].WSOHM_ADDRESS as string, wsOHM, provider);
    const wtosBalance = await wsohmContract.wRICHTosRICH(ethers.utils.parseUnits('1', 9))
    const stowBalance = await wsohmContract.sRICHTowRICH(ethers.utils.parseUnits('1', 9))
    return {
      currentIndex: ethers.utils.formatUnits(currentIndex, "gwei"),
      oneDayRate,
      warmupPeriod,
      currentBlock,
      fiveDayRate,
      stakingAPY,
      stakingTVL,
      endBlock,
      stakingRebase,
      marketPrice,
      wtosBalance,
      stowBalance,
    };
  },
);

export const loadGovernanceDetails = createAsyncThunk(
  "app/loadGovernanceDetails",
  async ({ networkID, provider }: IBaseAsyncThunk, { dispatch }) => {

    const wsohmContract = new ethers.Contract(addresses[networkID].WSOHM_ADDRESS as string, wsOHM, provider);
    const lockedWRICH = await wsohmContract.balanceOf(addresses[networkID].vy)//TODO:
    const lockedRICH = await wsohmContract.wRICHTosRICH(lockedWRICH)
    const richContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20Abi, provider);
    const richtotalSupply = Number(await richContract.totalSupply());
    const cicrRICHLocked = lockedRICH / richtotalSupply;
    const stakeContract = new ethers.Contract(addresses[networkID].stake as string, stake, provider);
    const rewardAmount = await stakeContract.rewardAmount()
    const stakeBalance = await richContract.balanceOf(addresses[networkID].stake)
    const unallocatedReward = Number(stakeBalance) - Number(rewardAmount)
    const yieldForDuration = await stakeContract.getYieldForDuration()
    const vyContract = new ethers.Contract(addresses[networkID].vy as string, vyABI, provider)
    const avgLockTime = ((Number(await vyContract.totalSupply()) + Number(await vyContract.supply())) / lockedWRICH - 1) / 3 * 1460
    const apr = yieldForDuration / lockedRICH * 365 / 7
    return {
      lockedRICH: ethers.utils.formatUnits(lockedRICH, "gwei"),
      cicrRICHLocked,
      unallocatedReward: unallocatedReward / Math.pow(10, 9),
      yieldForDuration: ethers.utils.formatUnits(yieldForDuration, "gwei"),
      apr,
      avgLockTime
    };
  },
);

/**
 * checks if app.slice has marketPrice already
 * if yes then simply load that state
 * if no then fetches via `loadMarketPrice`
 *
 * `usage`:
 * ```
 * const originalPromiseResult = await dispatch(
 *    findOrLoadMarketPrice({ networkID: networkID, provider: provider }),
 *  ).unwrap();
 * originalPromiseResult?.whateverValue;
 * ```
 */
export const findOrLoadMarketPrice = createAsyncThunk(
  "app/findOrLoadMarketPrice",
  async ({ networkID, provider }: IBaseAsyncThunk, { dispatch, getState }) => {
    const state: any = getState();
    let marketPrice;
    // check if we already have loaded market price
    if (state.app.loadingMarketPrice === false && state.app.marketPrice) {
      // go get marketPrice from app.state
      marketPrice = state.app.marketPrice;
    } else {
      // we don't have marketPrice in app.state, so go get it
      try {
        const originalPromiseResult = await dispatch(
          loadMarketPrice({ networkID: networkID, provider: provider }),
        ).unwrap();
        marketPrice = originalPromiseResult?.marketPrice;
      } catch (rejectedValueOrSerializedError) {
        // handle error here
        console.error("Returned a null response from dispatch(loadMarketPrice)");
        return;
      }
    }
    return { marketPrice };
  },
);

/**
 * - fetches the OHM price from CoinGecko (via getTokenPrice)
 * - falls back to fetch marketPrice from ohm-dai contract
 * - updates the App.slice when it runs
 */
const loadMarketPrice = createAsyncThunk("app/loadMarketPrice", async ({ networkID, provider }: IBaseAsyncThunk) => {
  let marketPrice: number;
  try {
    marketPrice = await getMarketPrice({ networkID, provider });
    marketPrice = marketPrice * Math.pow(10, 3);
  } catch (e) {
    marketPrice = await getTokenPrice();
  }
  return { marketPrice };
});



const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAppDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadAppDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAppDetails.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(loadGovernanceDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadGovernanceDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadGovernanceDetails.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(loadMarketPrice.pending, (state, action) => {
        state.loadingMarketPrice = true;
      })
      .addCase(loadMarketPrice.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loadingMarketPrice = false;
      })
      .addCase(loadMarketPrice.rejected, (state, { error }) => {
        state.loadingMarketPrice = false;
      });
  },
});

const baseInfo = (state: RootState) => state.app;

export default appSlice.reducer;

export const { fetchAppSuccess } = appSlice.actions;

export const getAppState = createSelector(baseInfo, app => app);
