import { ThemeProvider } from "@material-ui/core/styles";
import { useEffect, useState, useCallback, lazy, Suspense } from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import { Hidden, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import useTheme from "./hooks/useTheme";
import { light as lightTheme } from "./themes/light.js";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import LoadingSplash from "./components/LoadingSplash";
import { useWeb3Context } from "./hooks";
import { storeQueryParameters } from "./helpers/QueryParameterHelper";
import { loadAppDetails, loadGovernanceDetails } from "./slices/AppSlice";
import { loadAccountDetails } from "./slices/AccountSlice";
const DEBUG = false;
import Messages from "./components/Messages/Messages";
const Home = lazy(() => import("./views/home"));
const More = lazy(() => import("./views/more"));
const RichLand = lazy(() => import("./views/richLand"));
const Stake = lazy(() => import('./views/Stake/Stake'));
const Governance = lazy(() => import('./views/governance/Governance'));
const Marketplace = lazy(() => import('./views/marketplace'))
if (DEBUG) console.log(" Connecting to Mainnet Ethereum");

// const blockExplorer = targetNetwork.blockExplorer;

function App() {
  const [theme, mounted] = useTheme();
  const location = useLocation();
  const [pathname, setPathname] = useState(location.pathname);
  const { connect, hasCachedProvider, provider, chainID, connected, address } = useWeb3Context();
  const dispatch = useDispatch()
  const [walletChecked, setWalletChecked] = useState(false);
  async function loadDetails(whichDetails) {
    let loadProvider = provider;
    if (whichDetails === "app") {
      loadApp(loadProvider);
    }
    if (whichDetails === "account" && address && connected) {
      loadAccount(loadProvider);
    }
  }

  const loadApp = useCallback(
    loadProvider => {
      dispatch(loadAppDetails({ networkID: chainID, provider: loadProvider }));
      dispatch(loadGovernanceDetails({ networkID: chainID, provider: loadProvider }));
    },
    [connected],
  );

  const loadAccount = useCallback(
    loadProvider => {
      dispatch(loadAccountDetails({ networkID: chainID, address, provider: loadProvider }));
    },
    [connected],
  );

  useEffect(() => {
    if (hasCachedProvider()) {
      // then user DOES have a wallet
      connect().then(() => {
        setWalletChecked(true);
      });
    } else {
      // then user DOES NOT have a wallet
      setWalletChecked(true);
    }
    storeQueryParameters();
  }, []);

  useEffect(() => {
    if (walletChecked) {
      loadDetails("app");
    }
  }, [walletChecked]);

  useEffect(() => {
    if (connected) {
      loadDetails("account");
    }
  }, [connected]);


  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  let themeMode = lightTheme;

  return (
    <ThemeProvider theme={themeMode}>
      <CssBaseline />
      <Messages />
      <Suspense fallback={<LoadingSplash />}>
        <div className={`'app-container'`}>
          <Header />
          <Switch>
            <Route exact path="/marketplace">
              <Marketplace />
            </Route>
            <Route exact path="/more">
              <More />
            </Route>
            <Route exact path="/richLand">
              <RichLand />
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/governance">
              <Stake />
              <Governance />
            </Route>
            <Route exact path="/">
              <Redirect to="/" />
            </Route>
          </Switch>
          <Footer />
        </div>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
