import { Box, Link, Dialog } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import './header.scss';
import Logo from '../../assets/images/logo.svg'
import Logo2 from '../../assets/images/logo2.svg'
import Close from '../../assets/images/close.svg'
import Menu from '../../assets/images/menu.svg'
import { useWeb3Context } from "src/hooks";
import { shorten } from "src/helpers";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

export default function Header() {

    const isSmallScreen = useMediaQuery("(max-width: 1020px)");
    const { connect, connected, disconnect, web3, address } = useWeb3Context()
    const [isConnected, setConnected] = useState(connected);
    const [openMenu, setOpenMenu] = useState(false);
    let clickFunc = connect;

    let buttonText = "Connect Wallet";

    if (isConnected) {
        buttonText = shorten(address); // "Disconnect";
        clickFunc = disconnect;
    }

    if (isSmallScreen && !isConnected) {
        buttonText = "Connect";
    }

    useEffect(() => {
        setConnected(connected);
    }, [web3, connected]);

    const handleCloseMenu = () => {
        setOpenMenu(false);
    };

    return (
        <div className='header-view'>
            {
                isSmallScreen ?
                    <Box display='flex' justifyContent='space-between' alignItems='center' className="header-m">
                        <div className="logoBox">
                        <img className="" src={Menu} onClick={() => { setOpenMenu(true) }} />
                        </div>
                        <Link component={NavLink} to="/">
                            <img className="" src={Logo2} style={{ height: '50px' }} />
                        </Link>
                        <Box display='flex' justifyContent='center' alignItems='center' className="enterApp color3 border-radius-4" onClick={clickFunc} >{buttonText}</Box>
                    </Box>
                    :
                    <Box className="header" display='flex' justifyContent='center'>
                        <Box display='flex' justifyContent='space-between' alignItems='center' className="container font-14 font-weight-bold">
                            <Link component={NavLink} to="/">
                            <div className="logoBox">
                                <img className="" src={Logo} />
                                </div>
                            </Link>
                            <Box display='flex' className="menuList">
                                <a href="#games" target="_self" className="menuItem">
                                    <div className="color1">Games</div>
                                </a>
                                <Link component={NavLink} to="/governance" className="menuItem">
                                    <div className="color1">Governance</div>
                                </Link>
                                <Link component={NavLink} to="/marketplace" className="menuItem">
                                    <div className="color1">Marketplace</div>
                                </Link>
                            </Box>
                            <Box display='flex' justifyContent='center' alignItems='center' className="enterApp color3 border-radius-4" onClick={clickFunc}>{buttonText}</Box>
                        </Box>
                    </Box>
            }
            <Dialog
                fullScreen
                open={openMenu}
                onClose={handleCloseMenu} >
                <div className='menu-container font-16'>
                    <Box display='flex' flexDirection='column' alignItems='center'>
                        <img src={Logo2} className="logo2" style={{ height: "195px" }} />
                        <div className="menuList">
                            <Link component={NavLink} to="/governance" className="menuItem" onClick={handleCloseMenu}>
                                <Box display='flex' alignItems='center' justifyContent='center' className="color1">Governance</Box>
                            </Link>
                            <Link component={NavLink} to="/marketplace" className="menuItem mt-20" onClick={handleCloseMenu}>
                                <Box display='flex' alignItems='center' justifyContent='center' className="color1">Marketplace</Box>
                            </Link>
                        </div>
                        <img className="close" src={Close} onClick={handleCloseMenu} />
                    </Box>
                </div>
            </Dialog>
        </div>
    )
}