

interface IAddresses {
  [key: number]: { [key: string]: string };
}
//randomSource :  0x5eeb846851aa8E82941510C529bbcF6DcEDe8fF4
export const addresses: IAddresses = {
  250: {
    OHM_ADDRESS: "0x5A272C478609F448C566c7155577f6432fdd8B79", //
    STAKING_ADDRESS: "0x319995E79c662479d42C054fdAB0415A6404190D",
    STAKING_HELPER_ADDRESS: "0xEbB86efd194eBAAA4E43C226488cE4E48F5B6A46",
    SOHM_ADDRESS: "0xc59a271F7625f2195C1C38f8720DA00A52A72B10",
    USDC_ADDRESS: "0x04068da6c83afcfa0e13ba15a6696662335d5b75", //
    LP_ADDRESS: '0x937813380c7b98a66Afa5992bf2231E3E5913eF4',
    WSOHM_ADDRESS: "0x7A32Ae9F4E1139b7AE96A0B971332E2B9af72b56",
    vy: "0x8761C198D28963Bd539911c17B50BBEB15F8BB95",
    stake: "0xE877913f897eC93084F148A729181720a0320F2A",
    stakehelper: "0x3916ee21d2Db7e8b550c966Be3EeA6ec9E5EAECc",
    CASH_ADDRESS: "0xbb4f92034927d4cE195b7D8f0acD4b3f661c5c45",
    RichCityLand: "0xB379268B57c85a4D8Bf6bD22A7488D6C8464cd67",
  },
  97: {
    OHM_ADDRESS: "0xea65d357f005Dd52f7102C675974D948f509A99F", //
    STAKING_ADDRESS: "0xBCbC558d4aB44F31F7F002c8fC1507239C9F9791",
    STAKING_HELPER_ADDRESS: "0x245d6edFB96046692FD826bB82cee2AdfFaBAA5C",
    SOHM_ADDRESS: "0x781951f206c404A55001D9801Bf16Bd560a376B1",
    USDC_ADDRESS: "0x8e088E79d554a75d511b390E021839f7877Ce0Aa", //
    LP_ADDRESS: '0xd67dDd14fDeFB8d1F110Ea937CcE7E6271BD3Cd0',
    WSOHM_ADDRESS: "0x1fF23Eb6F7b7183045DE8d750f017009fE239EC2",
    vy: "0x247D6a00774002016f8a965858316E6E35a0Ea21",
    stake: "0x931f134791aAFfc7f4370270773CaA60EC21B198",
    stakehelper: "0x2faAe1A7792fe28ab24FE1721b4DA65fCf19d47D",
    CASH_ADDRESS: "0x5109ED18C5F3E22a3F3a4849c8e611F9dd80447D",
    RichCityLand: "0xF431FDD2d128Ba817c01557Ad625074851d7F356",
  },
  4002: {
    CASH_ADDRESS: "0x9405C577bd5534cE3576278Cd864Cd46F5AA60a1",
    RichCityLand: "0x28887a1748ADE1839237D9E0431711048531D8fC",
  }
};



