import { Box,Link } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import './footer.scss';
import Logo from '../../assets/images/logo.svg'
import LogoF from '../../assets/images/logo-f.svg'
import FooterIcon1 from '../../assets/images/footer/footerIcon.svg'
import FooterIcon2 from '../../assets/images/footer/footerIcon2.svg'
import FooterIcon3 from '../../assets/images/footer/footerIcon3.svg'
import FooterIcon4 from '../../assets/images/footer/footerIcon4.svg'
import FooterIcon5 from '../../assets/images/footer/footerIcon5.svg'
import FooterIcon6 from '../../assets/images/footer/footerIcon6.svg'
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { info } from "src/slices/MessagesSlice";

export default function Footer() {

    const isSmallScreen = useMediaQuery("(max-width: 1020px)");
    const dispatch = useDispatch()
    const handleOpen = () => {
        dispatch(info("Coming soon"))
    };

    return (
        <Box className='footer-view bg1' display='flex' justifyContent='center'>
            <div className="container2">
                <Box className='footer' display='flex' justifyContent='space-between' alignItems='center'>
                    <img className="logo" src={Logo} />
                    <Box display='flex'>
                        <a href="https://twitter.com/RichCityDAO" target='_blank' className="">
                            <img className="footerIcon" src={FooterIcon1} />
                        </a>
                        <a href="https://discord.gg/jHnNPxgcVY" target='_blank' className="ml-16">
                            <img className="footerIcon" src={FooterIcon2} />
                        </a>
                        {/* <a href="" target='_blank' className="ml-16">
                            <img className="footerIcon" src={FooterIcon3} />
                        </a> */}
                        <a href="https://medium.com/@RichCity" target='_blank' className="ml-16">
                            <img className="footerIcon" src={FooterIcon4} />
                        </a>
                        {/* <a href="" target='_blank' className="ml-16">
                            <img className="footerIcon" src={FooterIcon5} />
                        </a> */}
                        <a href="https://www.youtube.com/channel/UCGQSSBtpMG4dvRGd2vs4Xrw" target='_blank' className="ml-16">
                            <img className="footerIcon" src={FooterIcon6} />
                        </a>
                    </Box>
                </Box>
                <Box className='footerList mt-30 font-14' display='flex'>
                    <div className="footerItem footerItem1">
                        <div className="color2 font-12 font-weight-bold">RICH CITY</div>
                        <a href="https://docs.richcity.app" target="_blank">
                            <div className="mt-8 color1">Document</div>
                        </a>
                        <Link component={NavLink} to="/governance">
                            <div className="mt-8 color1">Governance</div>
                        </Link>
                    </div>
                    <div className="footerItem footerItem2">
                        <div className="color2 font-12 font-weight-bold">RICH Land</div>
                        <Link component={NavLink} to="/richLand">
                            <div className="mt-8 color1 land">Buy Land</div>
                        </Link>
                    </div>
                    <div className="footerItem footerItem3">
                        <div className="color2 font-12 font-weight-bold">Game</div>
                        <a href="https://gl.richcity.app" target="_blank">
                            <div className="mt-8 color1">Gangster Legends</div>
                        </a>
                        {/* <a href="" target="_blank"> */}
                            <div className="mt-8 color1">Charming House</div>
                        {/* </a>
                        <a href="" target="_blank"> */}
                            <div className="mt-8 color1">Bussiness Legends</div>
                        {/* </a>
                        <a href="" target="_blank"> */}
                            <div className="mt-8 color1">Gotham CIty</div>
                        {/* </a>
                        <a href="" target="_blank"> */}
                            <div className="mt-8 color1">Rich Wars</div>
                        {/* </a> */}
                    </div>
                </Box>
                {
                    isSmallScreen && 
                    <Box className='' display='flex' justifyContent='center'>
                    <img src={LogoF} className="logo-footer" />
                    </Box>
                }
            </div>
        </Box>
    )
}