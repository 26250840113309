import MontserratItalicVariableFontWOFF from "../assets/fonts/Montserrat-Italic-VariableFont.woff";
import MontserratVariableFontWOFF from "../assets/fonts/Montserrat-VariableFont.woff";
import NeonOneWOFF from "../assets/fonts/Neon-One.woff";

const neonOne = {
  fontFamily: "Neon-One",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('NeonOne'),
		local('Neon-One'),
		url(${NeonOneWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const montserratItalicVariableFont = {
  fontFamily: "Montserrat-Italic-VariableFont",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('MontserratItalicVariableFont'),
		local('Montserrat-Italic-VariableFont'),
		url(${MontserratItalicVariableFontWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const montserratVariableFont = {
  fontFamily: "Montserrat-VariableFont",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('MontserratVariableFont'),
		local('Montserrat-VariableFont'),
		url(${MontserratVariableFontWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const fonts = [montserratItalicVariableFont,montserratVariableFont,neonOne];

export default fonts;
